
import Overview from "../Pages/Overview";
import '../App.css';
import BarChart from "../Components/BarChart.jsx"
import LineChart from "../Components/LineChart.jsx"
import { Button, HStack,VStack } from '@chakra-ui/react';
import TarjetasOverview from '../Components/TarjetasOverview.js';
import BotonesOverview from "../Components/BotonesOverview.js"
import PersonaVentaOverview from '../Components/PersonaVentaOverview.js';
import Calendario from "./Calendario.js";
import { useState } from "react";
function BotonesAnalitica({opcion,setOpcion}) {
    return (
        <VStack>
            <HStack marginTop={"20px"}>
                <button className={opcion===0 ? 'boton-analitica-seleccionado':'boton-analitica'} onClick={() =>{setOpcion(0)}}>Tendencias temporales</button>
                <button className={opcion===1 ? 'boton-analitica-seleccionado':'boton-analitica'} onClick={() =>{setOpcion(1)}}>Temperatura conversaciones</button>
            </HStack>
        </VStack>
    );
}
  
  export default BotonesAnalitica;



