import { VStack, HStack, Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import "../App.css";

function MessageInbox(props) {
  const Bolita = () => {
    const [estilos] = useState({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "22px",
      height: "22px",
      backgroundColor: "#9E2D1D",
      borderRadius: "50%",
      fontsize: "12px",
      color: "white",
    });

    return <div style={estilos}>{props.unseen}</div>;
  };

  const inputDate = new Date(props.createdAt);

  // Extract year, month, and day from the input date
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getUTCDate()).padStart(2, "0");

  // Format the date to the desired output format "MM/DD/YYYY"
  const formattedDate = `${month}/${day}/${year}`;
  return (
    <VStack className="message-contenedor" spacing="0px">
      <HStack paddingTop={"5px"} w="100%">
        <Text className="message-nombre">{props.nombre}</Text>
        <Box>
          <Text className="message-fecha">{formattedDate}</Text>
        </Box>
      </HStack>
      <HStack w="100%">
        <Text className="message-promocion">{props.contact}</Text>
        {props.unseen > 0 ? (
          <div className="message-notificacion">
            <Bolita />
          </div>
        ) : (
          <></>
        )}
      </HStack>
    </VStack>
  );
}

export default MessageInbox;
