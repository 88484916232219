export const cognitoVocabulary = {
    es: {
        'Sign in to your account': 'Inicia sesión en tu cuenta',
        'Username': 'Nombre de usuario',
        'Password': 'Contraseña',
        'Sign In': 'Iniciar Sesión',
        'Sign in': 'Iniciar Sesión',
        'Sign Up': 'Registrarse',
        'Confirm Sign Up': 'Confirmar registro',
        'Confirm Password': 'Confirmar contraseña',
        'Name': 'Nombre',
        'Email': 'Correo electrónico',
        'Enter your Username': 'Ingresa tu nombre de usuario',
        'Enter your username': 'Ingresa tu nombre de usuario',
        'Enter your Password': 'Ingresa tu contraseña',
        'Enter your Email': 'Ingresa tu correo electrónico',
        'Enter your code': 'Ingresa tu código',
        'Resend Code': 'Reenviar código',
        'Enter your Name': 'Ingresa tu nombre',
        'Back to Sign In': 'Regresar a iniciar sesión',
        'Forgot your password?': '¿Olvidaste tu contraseña?',
        'Reset Password': 'Restablecer tu contraseña',
        'Send code': 'Enviar código',
        'New Password': 'Nueva contraseña',
        'Create a new account': 'Crear una nueva cuenta',
        'Email Address': 'Correo electrónico *',
        'Phone Number': 'Número de teléfono *',
        'Have an account?': '¿Ya tienes una cuenta?',
        'Create Account': 'Crear Cuenta',
        'Please confirm your Password': 'Por favor confirma tu contraseña',
        'Setup TOTP': 'Configurar TOTP',
        'Code': 'Código',
        'Code *': 'Código *',
        'Confirm': 'Confirmar',
        'Submit': 'Enviar',
        'Given Name': 'Nombre(s)',
        'Enter your Given Name': 'Ingresa tu(s) nombre(s)',
        'Family Name': 'Apellido(s)',
        'Enter your Family Name': 'Ingresa tu(s) apellido(s)',
    }
};