import '../App.css';
import { HStack } from '@chakra-ui/react';
import LineChart from '../Components/LineChart';
import HeatMap from '../Components/HeatMap';
function TendenciasTemporales() {

  return (
    <HStack marginTop={"20px"} justifyContent="space-between">
    <div className='div-grafica-temporales'>
      <LineChart/>
    </div>
    <div className='div-grafica-temporales'>
        <HeatMap/>
    </div>
    </HStack>
  );
}

export default TendenciasTemporales;
