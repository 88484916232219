import { VStack, HStack } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
import { useParams } from "react-router-dom";

function Pruebas() {
  const { id } = useParams();

  const BotonBolita = ({ color, isSelected, onClick }) => {
    const [estilos] = useState({
      width: "12px",
      height: "12px",
      backgroundColor: isSelected ? color : "#ccc",
      borderRadius: "50%",
    });

    return <div style={estilos} onClick={onClick}></div>;
  };

  const [ActivoSeleccionado, setActivoSeleccionado] = useState(false);
  const [InactivoSeleccionado, setInactivoSeleccionado] = useState(false);

  const setState = async () => {
    axios
      .get(`https://sls-chatbot.pernexium.com/v1/bots/get/${id}`, {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      })
      .then((res) => {
        if (res.data.enabled) {
          setActivoSeleccionado(true);
          setInactivoSeleccionado(false);
        } else {
          setActivoSeleccionado(false);
          setInactivoSeleccionado(true);
        }
      });
  };

  useEffect(() => {
    setState();
  }, []);

  const toggleActivoSeleccion = async () => {
    setActivoSeleccionado(true);
    setInactivoSeleccionado(false);
    axios.post(
      `https://sls-chatbot.pernexium.com/v1/bots/update/${id}`,
      { enabled: true },
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );
  };

  const toggleInactivoSeleccion = async () => {
    setActivoSeleccionado(false);
    setInactivoSeleccionado(true);
    axios.post(
      `https://sls-chatbot.pernexium.com/v1/bots/update/${id}`,
      { enabled: false },
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );
  };

  return (
    <VStack
      spacing={"20px"}
      className="div-ajuste-chatbot"
      justifyContent="flex-start"
      alignItems={"left"}
    >
      <p className="info-chatbot">Ajustes del chatbot</p>
      <p className="subtitulo-conocimiento"> Chatbot status</p>
      <p className="titulo-conocimiento">
        Podrá elegir el status de su chatbot por medio de activo donde envia
        mensajes o apagado donde este no responde
      </p>
      <HStack>
        <button
          className={ActivoSeleccionado ? "boton-activo" : "boton-desactivado"}
          onClick={toggleActivoSeleccion}
        >
          <BotonBolita color="#53A871" isSelected={ActivoSeleccionado} />
          Activo
        </button>
        <button
          className={
            InactivoSeleccionado ? "boton-activo" : "boton-desactivado"
          }
          onClick={toggleInactivoSeleccion}
        >
          <BotonBolita color="#9E2D1D" isSelected={InactivoSeleccionado} />
          Inactivo
        </button>
      </HStack>
    </VStack>
  );
}

export default Pruebas;
