import React, { useState } from 'react';
import '../App.css';
import { HStack } from '@chakra-ui/react';
import Anterior from "../images/anterior.png";
import Siguiente from "../images/siguiente.png";
function Calendario({ onDateSelect }) {
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);

  const prevMonth = () => {
    const currDate = new Date(date);
    currDate.setMonth(date.getMonth() - 1);
    setDate(currDate); 
  }
  
  const nextMonth = () => {
    const currDate = new Date(date); 
    currDate.setMonth(date.getMonth() + 1);
    setDate(currDate);
  }

  const months = [
    "Enero", "Febrero", "Marzo",
    "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre",
    "Octubre", "Noviembre", "Diciembre"
  ];

  const weekDays = [
    "Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"
  ];

  const handleDateClick = (day) => {
    setSelectedDate(day);
    onDateSelect(day);
  };

  const isDateSelected = (day) => {
    return selectedDate && selectedDate.getTime() === day.getTime();
  };

  const renderDay = (day) => {
    const classNames = ['day-calendar'];

    if (isDateSelected(day)) {
      classNames.push('selected-day');
    }

    return (
      <li key={day} className={classNames.join(' ')} onClick={() => handleDateClick(day)}>
        {day.getDate()}
      </li>
    );
  };

  const renderCalendar = () => {
    const totalDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const monthStartsOn = new Date(date.getFullYear(), date.getMonth(), 1).getDay();

    const days = [];

    // Clear the array at the beginning
    for (let i = monthStartsOn; i > 0; i--) {
        days.push(
          <li key={i}></li>
        ); 
      }

    for (let i = 1; i <= totalDays; i++) {
      const currentDay = new Date(date.getFullYear(), date.getMonth(), i);
      days.push(renderDay(currentDay));
    }

    return (
      <div>
        <HStack>
        <p className='texto-calendario'>{months[date.getMonth()]} {date.getFullYear()}</p>
          <button onClick={prevMonth}><img src={Anterior} alt='foto-anterior'></img></button>
          <button onClick={nextMonth}><img src={Siguiente} alt='foto-anterior'></img></button>
        </HStack>
        <ul>
          {weekDays.map(day => (
            <li key={day}>{day}</li>
          ))}
        </ul>

        <ul className="days">
          {days}
        </ul>
      </div>
    );
  };

  return (
    <div className="calendar">
      {renderCalendar()}
    </div>
  );
}

export default Calendario;
