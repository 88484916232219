import '../App.css';
import {VStack,HStack,Box } from '@chakra-ui/react'
function Campaings() {

  return (
    <VStack padding={"10vh"}>
      <Box alignItems='flex-start' marginRight={"auto"}>
        <p className='titulo-overview'>Agregar organización</p>
      </Box>
      <HStack spacing={"15vw"}>
        <p className='contexto-subtitulo'>Nombre de la organización</p>
        <input className='nombre-organizacion-input' placeholder='Nombre de la organizacion'></input>
      </HStack>
      <Box marginTop={"5vh"} alignItems='flex-end' marginLeft={"auto"}>
        <button className='archivo-2'>Crear organización</button>
      </Box>
    </VStack>
  );
}

export default Campaings;
