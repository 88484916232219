import "../App.css";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import { HStack, Box, Center, Modal } from "@chakra-ui/react";
import { fetchAuthSession } from "@aws-amplify/auth";
import { parsePhoneNumber } from "../utils/auth";
import axios from "axios";
import { useState, useEffect } from "react";

function Conversacion({
  contact,
  contactPhone,
  active,
  onOpen,
  messages,
  setMessages,
  setTempValue,
}) {
  const handleSend = async (value) => {
    try {
      const debtor = await axios.get(
        `https://api.pernexium.com/chatbot/api/debtor/telephone/${contact}`,
        {
          headers: {
            Authorization:
              "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
          },
        }
      );
      const conversation = await axios.get(
        `https://api.pernexium.com/chatbot/api/conversation/debtor/${debtor.data.id}`,
        {
          headers: {
            Authorization:
              "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
          },
        }
      );

      const session = await axios.get(
        `https://api.pernexium.com/chatbot/api/session/${conversation.data.sessionId}`,
        {
          headers: {
            Authorization:
              "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
          },
        }
      );

      const expiredTemplate = await axios.get(
        `https://api.pernexium.com/chatbot/api/message/lastTemplate/conversation/${conversation.data.id}`,
        {
          headers: {
            Authorization:
              "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
          },
        }
      );

      const messageRecovered = await axios.get(
        `https://api.pernexium.com/chatbot/api/message/${expiredTemplate.data.id}`,
        {
          headers: {
            Authorization:
              "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
          },
        }
      );

      console.log(messageRecovered.data.expired);

      if (
        expiredTemplate.data.has_transpired_24_hours === "True" &&
        messageRecovered.data.expired === false
      ) {
        let recipientPhone = contact.slice(0, 2) + contact.slice(3);
        let data = JSON.stringify({
          messaging_product: "whatsapp",
          to: recipientPhone,
          type: "template",
          template: {
            name: "bancoppel_retomar_conv", // A futuro esto será uno generico para cada bot
            language: {
              code: "es",
            },
          },
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `https://graph.facebook.com/v18.0/${session.data.id}/messages`,
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer EAAOZCtQ6khoABO2EXZCDhg0FRygEHNNzBwmeQttOk0VZBuA6BiTYib1Or9gaS04qmf1owZC0y63xC8TZBO5sGbUMGDgQrPjMvI78aXCLB0WQIJzpqfyIP3Ab63WJlRNafcBZA7hiBkcZCSFow1wk9YJYIxfsKdzEfOiZB6EpOFkQqmZBYNqxor45LZAsS5VonqPwatZCDWsyrzqKtzAXB2r",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });

        await axios.post( //guardamos el mensaje en la base de datos
          `https://api.pernexium.com/chatbot/api/message`,
          {
            role: "user",
            content:
              "Perdone la demora en nuestra respuesta, ¿Podría darnos más detalle al respecto?",
            conversationId: conversation.data.id,
            senderId: contact,
            direction: "outgoing",
            seen: 1,
          },
          {
            headers: {
              Authorization:
                "Bearer " +
                (await fetchAuthSession()).tokens.idToken.toString(),
            },
          }
        );

        await axios.patch(
          `https://api.pernexium.com/chatbot/api/message/${expiredTemplate.data.id}`, //TODO What should be the endpoint in v1 for this?
          { expired: true },
          {
            headers: {
              Authorization:
                "Bearer " +
                (await fetchAuthSession()).tokens.idToken.toString(),
            },
          }
        );
      } else {
        if (!active) {
          let recipientPhone = contact.slice(0, 2) + contact.slice(3);
          let data = JSON.stringify({
            messaging_product: "whatsapp",
            recipient_type: "individual",
            to: recipientPhone,
            type: "text",
            text: {
              preview_url: false,
              body: value,
            },
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `https://graph.facebook.com/v18.0/${session.data.id}/messages`,
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer EAAOZCtQ6khoABO2EXZCDhg0FRygEHNNzBwmeQttOk0VZBuA6BiTYib1Or9gaS04qmf1owZC0y63xC8TZBO5sGbUMGDgQrPjMvI78aXCLB0WQIJzpqfyIP3Ab63WJlRNafcBZA7hiBkcZCSFow1wk9YJYIxfsKdzEfOiZB6EpOFkQqmZBYNqxor45LZAsS5VonqPwatZCDWsyrzqKtzAXB2r",
              Cookie: "ps_l=0; ps_n=0",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
              console.log(error);
            });

          await axios.post(
            `https://api.pernexium.com/chatbot/api/message`,
            {
              role: "user",
              content: value,
              conversationId: conversation.data.id,
              senderId: contact,
              direction: "outgoing",
              seen: 1,
            },
            {
              headers: {
                Authorization:
                  "Bearer " +
                  (await fetchAuthSession()).tokens.idToken.toString(),
              },
            }
          );
          const newMessageKey = `message${Object.keys(messages).length + 1}`;
          const newMessage = {
            content: value,
            direction: "outgoing",
            createdAt: new Date().toISOString(),
          };

          setMessages((prevMessages) => ({
            ...prevMessages,
            [newMessageKey]: newMessage,
          }));
        } else {
          onOpen();
          setTempValue(value);
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const correctedTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  return (
    <div style={{ position: "relative", height: "100%", width: "100%", position: "relative", left: "-80px"  }}>
      <MainContainer>
        <ChatContainer>
          <MessageList autoScrollToBottomOnMount={true}>
            {Object.entries(messages).map(([key, message]) => (
              <Message
                key={key}
                model={{
                  message: message.content,
                  direction: message.direction,
                }}
              >
                <Message.Footer>
                  {correctedTime(message.createdAt)}
                </Message.Footer>
              </Message>
            ))}
          </MessageList>
          <HStack
            as="MessageInput"
            w="100%"
            bg="#DEDFE4"
            spacing="0px"
            h="120px"
          >
            <HStack w="8%" marginLeft="8px" spacing="10px">
              <i className="material-icons btn-icon">attach_file</i>
              <i className="material-icons btn-icon">image</i>
            </HStack>
            <Box w="90%">
              <MessageInput
                attachButton={false}
                style={{
                  width: "100%",
                  border: "none",
                  backgroundColor: "#DEDFE4",
                }}
                onSend={handleSend}
                sendDisabled={false}
                fancyScroll={false}
              />
            </Box>
          </HStack>
        </ChatContainer>
      </MainContainer>
    </div>
  );
}

export default Conversacion;
