function AvisoPrivacidad() {
    return (
        <div>
          <p className='info-chatbot'>Aviso de Privacidad</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat, risus eget tristique pulvinar, risus eros sodales neque, vel ullamcorper erat nisl et justo. Sed sed sapien nisi. Suspendisse nulla metus, feugiat vitae leo ut, ultricies tincidunt dui. Etiam laoreet, eros non scelerisque tincidunt, purus quam dictum sem, sit amet tristique massa felis ac augue. Curabitur ante justo, bibendum in placerat ac, varius sit amet urna. Nulla vitae ullamcorper lectus. Sed consectetur odio vitae tortor blandit, in aliquet libero semper. Fusce ac nunc nibh. Duis dapibus rutrum lacus vitae dapibus. Sed tempor, turpis ut accumsan vehicula, ligula nisl consequat nibh, sed maximus nunc ante et tortor. Integer mattis diam sit amet sem elementum dapibus. Nullam blandit fermentum iaculis. Proin porta arcu nec dui commodo interdum. Mauris eu eros auctor velit tempor varius. Duis eget viverra sapien, eget tristique nunc.</p>
        </div>
  
    );
  }
  
  export default AvisoPrivacidad;