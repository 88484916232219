import "../App.css";
import React from "react";
import Caja from "../images/caja.png";
import { VStack } from "@chakra-ui/react";

function Conversacionvacia() {
  return (
    <VStack className="conversacionvacia">
      <img src={Caja} alt="foto-Caja"></img>
      <p>Abre una conversación para verla aquí</p>
    </VStack>
  );
}

export default Conversacionvacia;
