import { Text } from "@chakra-ui/react";
import Botonregresar from "../Components/Botonregresar.js";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import "../App.css";
import Botones from "./Menubotones.js";
import General from "../Pages/General.js";
import Conocimiento from "../Pages/Conocimiento.js";
import Contexto from "../Pages/Contexto.js";
import Integraciones from "../Pages/Integraciones.js";
import Medio from "../Pages/Medio.js";
import Ajustes from "../Pages/Ajustes.js";
import Backtesting from "./Backtesting.jsx";
import {
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Center,
} from "@chakra-ui/react";
import axios from "axios";
import { CHATBOT_API_URL } from "../Constants";
import { fetchAuthSession, getCurrentUser } from "@aws-amplify/auth";
import { parsePhoneNumber } from "../utils/auth.js";

function Menu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setFile] = useState(null);
  const nombre = useParams();
  const [opcion, setOpcion] = useState(0);

  const sendMessages = (debtors) => {
    console.log(debtors);
    let counter = 0;

    debtors.map(async (debtor) => {

      const token = (await fetchAuthSession()).tokens.idToken.toString();

      const debtorInfo = await axios.get(
        `https://api.pernexium.com/chatbot/api/debtor/telephone/${debtor.phone1}`,
        {
          headers: {
            Authorization:
              "Bearer " + token,
          },
        }
      );

      const conversation = await axios.get(
        `https://api.pernexium.com/chatbot/api/conversation/debtor/${debtorInfo.data.id}`,
        {
          headers: {
            Authorization:
              "Bearer " + token,
          },
        }
      );

      if (conversation.data.status === "ws" && counter <= 250) {
        counter++;

        const session = await axios.get(
          `https://api.pernexium.com/chatbot/api/session/${conversation.data.sessionId}`,
          {
            headers: {
              Authorization:
                "Bearer " + token,
            },
          }
        );

        await axios.post(
          `https://api.pernexium.com/chatbot/api/message`,
          {
            role: "assistant",
            content: session.data.template,
            conversationId: conversation.data.id,
            senderId: debtor.phone1,
            direction: "outgoing",
          },
          {
            headers: {
              Authorization:
                "Bearer " +
                (await fetchAuthSession()).tokens.idToken.toString(),
            },
          }
        );

        let data = JSON.stringify({
          messaging_product: "whatsapp",
          to: debtor.phone1.slice(0, 2) + debtor.phone1.slice(3),
          type: "template",
          template: {
            name: session.data.templateName,
            language: {
              code: "es",
            },
          },
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `https://graph.facebook.com/v18.0/${session.data.id}/messages`,
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer EAAOZCtQ6khoABO2EXZCDhg0FRygEHNNzBwmeQttOk0VZBuA6BiTYib1Or9gaS04qmf1owZC0y63xC8TZBO5sGbUMGDgQrPjMvI78aXCLB0WQIJzpqfyIP3Ab63WJlRNafcBZA7hiBkcZCSFow1wk9YJYIxfsKdzEfOiZB6EpOFkQqmZBYNqxor45LZAsS5VonqPwatZCDWsyrzqKtzAXB2r",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });

        axios.patch(
          `https://api.pernexium.com/chatbot/api/conversation/${conversation.data.id}`,
          { status: "fc" },
          {
            headers: {
              Authorization:
                "Bearer " +
                (await fetchAuthSession()).tokens.idToken.toString(),
            },
          }
        );
      }
    });
  };

  const handleTest = async () => {
    fetchAuthSession().then((data) => {
      axios
        .get(`${CHATBOT_API_URL}/debtor/bot/${nombre.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + data.tokens.idToken.toString(),
          },
        })
        .then((res) => {
          sendMessages(res.data);
        });
    });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("file", file);

      await axios
        .post(`${CHATBOT_API_URL}/debtor`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(onClose);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div className="elemento-ventana">
      <HStack
        className="div-boton-regresar"
        justifyContent="flex-end"
        spacing="20px"
      >
        <Botonregresar />
        <button className="Probar-bot" onClick={handleTest}>
          Lanzar contactación
        </button>
      </HStack>
      <Text className="texto-nombre-menu">{nombre.id}</Text>

      <Text className="subtitulo">
        Maneja tus chatbots, sus status, conexto, data e integraciones.
      </Text>
      <Botones opcion={opcion} setOpcion={setOpcion} />
      {opcion === 0 && <General />}
      {opcion === 1 && <Ajustes />}
      {opcion === 2 && <Contexto />}
      {opcion === 3 && <Conocimiento />}
      {opcion === 4 && <Backtesting />}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent marginTop="200px">
          <ModalHeader>Subir deudores</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <input type="file" onChange={handleFileChange} />
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="teal" mr={3} type="submit">
                Subir
              </Button>
              <Button colorScheme="teal" mr={3} onClick={onClose}>
                Cerrar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default Menu;
