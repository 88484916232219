import Overview from "../Pages/Overview";
import MenuAnalitica from "./MenuAnalitica.js";
import '../App.css';
import { Button, HStack,VStack } from '@chakra-ui/react';
import BotonesOverview from "../Components/BotonesOverview.js"
import Reporte from '../Pages/Reporte.js';
import PopupCalendario from '../Components/Popupcalendario.js';
import { useRef, useState } from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function MenuOverview() {
    const [opcion, setOpcion] = useState(0);
    const pdfRef = useRef();
    const downloadpdf = () => {
        const input = pdfRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('l', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight/imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = 30;
            pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth*ratio, imgHeight*ratio)
            pdf.save('reporte.pdf');
        });
    }
    return (
        <>
        <div className="dashboard-espaciado">
        <VStack className='div-overview' alignItems='flex-start'>
            <HStack alignItems='flex-end' marginLeft={"auto"}>
                <PopupCalendario/>
                <button onClick={downloadpdf} className='boton-download' >Download</button>
                </HStack>
            <p className='titulo-overview'>Dashboard (prueba)</p>
        </VStack>
        <div className='div-botones-overview'>
            <BotonesOverview opcion={opcion} setOpcion={setOpcion}/>
        </div>
            <div ref={pdfRef}>
                {opcion === 0 && <Overview/>}
                {opcion === 1 && <MenuAnalitica/>}
                {opcion === 2 && <Reporte/>}
            </div>
        </div>
        </>
    );
}
  
  export default MenuOverview;



