import "../App.css";
import { HStack } from "@chakra-ui/react";
function Menubotones({ opcion, setOpcion }) {
  return (
    <div className="contenedor-bo">
      <HStack spacing="70px">
        <button
          className={
            opcion === 0 ? "boton-general-seleccionado" : "boton-general"
          }
          onClick={() => {
            setOpcion(0);
          }}
        >
          General
        </button>
        <button
          className={
            opcion === 1 ? "boton-pruebas-seleccionado" : "boton-pruebas"
          }
          onClick={() => {
            setOpcion(1);
          }}
        >
          Ajustes
        </button>
        <button
          className={
            opcion === 2 ? "boton-contexto-seleccionado" : "boton-contexto"
          }
          onClick={() => {
            setOpcion(2);
          }}
        >
          Contexto
        </button>
        <button
          className={
            opcion === 3
              ? "boton-conocimiento-seleccionado"
              : "boton-conocimiento"
          }
          onClick={() => {
            setOpcion(3);
          }}
        >
          Base de conocimiento
        </button>
        <button
          className={
            opcion === 4
              ? "boton-conocimiento-seleccionado"
              : "boton-conocimiento"
          }
          onClick={() => {
            setOpcion(4);
          }}
        >
          Backtesting
        </button>
      </HStack>
    </div>
  );
}

export default Menubotones;
