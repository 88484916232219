import '../App.css';
import BarChart from "../Components/BarChart.jsx"
import { HStack,VStack } from '@chakra-ui/react';
import TarjetasOverview from '../Components/TarjetasOverview.js';
import PersonaVentaOverview from '../Components/PersonaVentaOverview.js';
import ingresos_totales from "../images/ingresos_totales.png";
import venta from "../images/venta.png";
import contactacion_exitosa from "../images/contactacion_exitosa.png";
import contactacion_pendiente from "../images/contactacion_pendiente.png";
import { useState } from "react";

function Overview() {
  const [opcion, setOpcion] = useState(0);
  const tarjetas = [
    { titulo: 'Ingresos totales', message: "$45,231.89", aumento: "+20",imagen:ingresos_totales},
    { titulo: 'Venta', message: "$131,313.89", aumento: "+14",imagen:venta },
    { titulo: 'Contactación exitosa', message: "+777", aumento: "+14",imagen:contactacion_exitosa },
    { titulo: 'Contactación pendientes', message: "300", aumento: "+5",imagen:contactacion_pendiente }]
  const hStacks = [];

  for (let i = 0; i < tarjetas.length; i += 4) {
    const studentsInRow = tarjetas.slice(i);
    const hStack = (
      <HStack justifyContent="space-between" marginTop="10px"  width="100%">
        {studentsInRow.map((tarjetas) => (
          <TarjetasOverview
            titulo={tarjetas.titulo}
            message={tarjetas.message}
            aumento={tarjetas.aumento}
            imagen={tarjetas.imagen}
            mostrarTexto={true}
          />
        ))}
      </HStack>
    );
    hStacks.push(hStack);
  }  
  const PersonaVentas = [
    { nombre: 'Persona 1', numero: "555-345-6789", ganancia: "1,999.00" },
    { nombre: 'Persona 2', numero: "555-234-5678", ganancia: "39.00" },
    { nombre: 'Persona 3', numero: "555-234-5678", ganancia: "299.00" },
    { nombre: 'Persona 4', numero: "555-234-5678", ganancia: "99.00" },
    { nombre: 'Persona 5', numero: "555-234-5678", ganancia: "99.00" }]
    const vStacks = []; 
    for (let i = 0; i < PersonaVentas.length; i += 5) {
      const personasenfila = PersonaVentas.slice(i);
      const vStack = (
        <VStack spacing='10px' marginTop={"10px"} >
          {personasenfila.map((ventas) => (
            <PersonaVentaOverview
              nombre={ventas.nombre}
              numero={ventas.numero}
              ganancia={ventas.ganancia}
              mostrarTexto={true}
            />
            
          ))}
        </VStack>
      );
      vStacks.push(vStack);
  
    }  
    return (
    <>
    <div className='div-venta-tarjetas'>
      {hStacks}
    </div>
    <HStack  marginTop={"20px"} justifyContent="space-between">
      <div className='div-grafica-overview'>
        <BarChart/>
      </div>
      <div className='div-ventas-overview'>
        <p className='div-ventas-titulo'>Ventas recientes</p>
        <p className='div-ventas-texto'>Se realizaron {PersonaVentas.length} compras este mes.</p>
        {vStacks}
      </div>
    </HStack>
    </>
  );
}

export default Overview;
