import { HStack, Flex, Spacer, Box } from "@chakra-ui/react";
import "../App.css";
import Etiqueta from "../Components/Etiqueta";
import Chatbotcomponent from "../Components/chatbot";
import { useEffect, useState } from "react";
import axios from "axios";
import { CHATBOT_API_URL } from "../Constants";
import { fetchAuthSession } from "@aws-amplify/auth";

function Chatbot() {
  const [bots, setBots] = useState([]);
  const hStacks = [];

  useEffect(() => {
    fetchAuthSession().then((data) => {
      axios
        .get(`https://sls-chatbot.pernexium.com/v1/bots`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + data.tokens.idToken.toString(),
          },
        })
        .then((res) => {
          setBots(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }, []);

  for (let i = 0; i < bots.length; i += 3) {
    const botsInRow = bots.slice(i, i + 3);
    const hStack = (
      <Flex marginTop="20px" w="100%">
        {botsInRow.map((bot, index) => (
          <>
            <Etiqueta nombre={bot.name} status="Active" mostrarTexto={true} />
            {index === 2 ? <></> : <Spacer />}
          </>
        ))}
        {botsInRow.length === 2 && <Box w="323px" />}
      </Flex>
    );
    hStacks.push(hStack);
  }

  return (
    <div className="elemento-ventana">
      <h1 className="titulo">Chatbots</h1>
      <p>
        Salva miles de horas humanas creando asistentes personalizados (AI) para
        tu organización.
      </p>
      <HStack marginTop={"20px"}>
        <Chatbotcomponent />
      </HStack>
      {hStacks}
    </div>
  );
}

export default Chatbot;
