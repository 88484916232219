import { EditIcon } from "@chakra-ui/icons";
import "../../App.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  VStack,
  Button,
  Select,
  HStack,
} from "@chakra-ui/react";
import { CHATBOT_API_URL } from "../../Constants";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { useEffect, useState } from "react";

export default function NuevoMensaje({ fetchDebtors }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [credit, setCredit] = useState("");
  const [productType, setProductType] = useState("");
  const [firstName1, setFirstName1] = useState("");
  const [firstName2, setFirstName2] = useState("");
  const [lastName1, setLastName1] = useState("");
  const [lastName2, setLastName2] = useState("");
  const [currentBalance, setCurrentBalance] = useState("");
  const [discount, setDiscount] = useState("");
  const [phone1, setPhone1] = useState("");
  const [bots, setBots] = useState([]);
  const [botName, setBotName] = useState("");
  const [botSelected, setBotSelected] = useState(false);
  const [sessionId, setSessionId] = useState();

  function getBots() {
    fetchAuthSession().then((data) => {
      axios
        .get(`${CHATBOT_API_URL}/bot`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + data.tokens.idToken.toString(),
          },
        })
        .then(async (res) => {
          const newBots = res.data.map((bot) => ({
            id: bot.id,
            name: bot.name,
          }));
          setBots(newBots);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async function getSessionId() {
    axios
      .get(`https://api.pernexium.com/chatbot/api/session/bot/${botName}`, {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      })
      .then((response) => {
        setSessionId(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getBots();
  }, []);

  useEffect(() => {
    if (botSelected) {
      getSessionId();
    }
  }, [botSelected]);

  const handleSubmit = async () => {
    try {
      await axios.post(
        `https://api.pernexium.com/chatbot/api/debtor/createSingle`,
        {
          firstName1: firstName1,
          firstName2: firstName2,
          lastName1: lastName1,
          lastName2: lastName2,
          phone1: phone1,
          credit: credit,
          productType: productType,
          currentBalance: currentBalance,
          discount: discount,
          botName: botName,
          sessionId: sessionId,
        },
        {
          headers: {
            Authorization:
              "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
          },
        }
      );

      const debtor = await axios.get(
        `https://api.pernexium.com/chatbot/api/debtor/telephone/${phone1}`,
        {
          headers: {
            Authorization:
              "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
          },
        }
      );

      const conversation = await axios.get(
        `https://api.pernexium.com/chatbot/api/conversation/debtor/${debtor.data.id}`,
        {
          headers: {
            Authorization:
              "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
          },
        }
      );

      await axios.post(
        `https://api.pernexium.com/chatbot/api/message`,
        {
          role: "user",
          content:
            "Buen día, le atendemos de Dirsa en representación de BanCoppel, le escribo debido al adeudo que tiene con BanCoppel.",
          conversationId: conversation.data.id,
          senderId: phone1,
          direction: "outgoing",
        },
        {
          headers: {
            Authorization:
              "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
          },
        }
      );

      let recipientPhone = phone1.slice(0, 2) + phone1.slice(3);
      let data = JSON.stringify({
        messaging_product: "whatsapp",
        to: recipientPhone,
        type: "template",
        template: {
          name: "bancoppel_generico", // A futuro esto será uno generico para cada bot
          language: {
            code: "es",
          },
        },
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://graph.facebook.com/v18.0/${sessionId}/messages`,
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer EAAOZCtQ6khoABO2EXZCDhg0FRygEHNNzBwmeQttOk0VZBuA6BiTYib1Or9gaS04qmf1owZC0y63xC8TZBO5sGbUMGDgQrPjMvI78aXCLB0WQIJzpqfyIP3Ab63WJlRNafcBZA7hiBkcZCSFow1wk9YJYIxfsKdzEfOiZB6EpOFkQqmZBYNqxor45LZAsS5VonqPwatZCDWsyrzqKtzAXB2r",
          Cookie: "ps_l=0; ps_n=0",
        },
        data: data,
      };

      axios.request(config).then((response) => {
        console.log(JSON.stringify(response.data));
      });

      axios.patch(
        `https://api.pernexium.com/chatbot/api/conversation/${conversation.data.id}`,
        { status: "fc" },
        {
          headers: {
            Authorization:
              "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
    fetchDebtors();
    onClose();
  };

  return (
    <>
      <button
        className="botones-message-filtro"
        onClick={() => {
          onOpen(true);
        }}
      >
        <EditIcon />
      </button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nueva conversación</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={"10px"} marginLeft={"0px"} alignItems="center">
              {botSelected ? (
                <>
                  <input
                    onChange={(event) => setFirstName1(event.target.value)}
                    className="nombre-chatbot-modal"
                    placeholder="Primer Nombre"
                  ></input>
                  <input
                    onChange={(event) => setFirstName2(event.target.value)}
                    className="nombre-chatbot-modal"
                    placeholder="Segundo Nombre"
                  ></input>
                  <input
                    onChange={(event) => setLastName1(event.target.value)}
                    className="nombre-chatbot-modal"
                    placeholder="Primer Apellido"
                  ></input>
                  <input
                    onChange={(event) => setLastName2(event.target.value)}
                    className="nombre-chatbot-modal"
                    placeholder="Segundo Apellido"
                  ></input>
                  <input
                    onChange={(event) => setPhone1(event.target.value)}
                    className="nombre-chatbot-modal"
                    placeholder="Número"
                  ></input>
                  <input
                    onChange={(event) => setCredit(event.target.value)}
                    className="nombre-chatbot-modal"
                    placeholder="Número de Credito"
                  ></input>
                  <input
                    onChange={(event) => setProductType(event.target.value)}
                    className="nombre-chatbot-modal"
                    placeholder="Tipo de Producto"
                  ></input>
                  <input
                    onChange={(event) => setCurrentBalance(event.target.value)}
                    className="nombre-chatbot-modal"
                    placeholder="Saldo Total"
                  ></input>
                  <input
                    onChange={(event) => setDiscount(event.target.value)}
                    className="nombre-chatbot-modal"
                    placeholder="Descuento"
                  ></input>
                </>
              ) : (
                <Select
                  onChange={(event) => setBotName(event.target.value)}
                  placeholder="Selecciona un bot"
                  className="nombre-chatbot-modal"
                >
                  {bots.map((bot) => (
                    <option key={bot.id} value={bot.name}>
                      {bot.name}
                    </option>
                  ))}
                </Select>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            {botSelected ? (
              <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                Mandar mensaje
              </Button>
            ) : (
              <HStack>
                <Button colorScheme="red" mr={3} onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={() => {
                    setBotSelected(true);
                  }}
                >
                  Siguiente
                </Button>
              </HStack>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
