
import { ResponsiveHeatMap } from '@nivo/heatmap'
import { mockDataTeam as data} from '../data/dataHeatmap'
const MyResponsiveHeatMap = () => (
    <>
    <div className='heat-graph'>
    <ResponsiveHeatMap
        data={data}
        margin={{ top: 60, right: 90, bottom: 60, left: 90 }}
        valueFormat=" >-.2s"
        axisTop={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -390,
            legend: 'Horas',
            legendPosition: 'middle',
            legendOffset: -50
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'día',
            legendPosition: 'middle',
            legendOffset: -72
        }}
        colors={{
            type: 'diverging',
            scheme: 'purple_blue',
            minValue: 0,
            maxValue: 100000,
            divergeAt: 0.5
        }}
        emptyColor="#ffffff"
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    '0.9'
                ]
            ]
        }}
        legends={[
            {
                anchor: 'bottom',
                translateX: 0,
                translateY: 30,
                length: 400,
                thickness: 8,
                direction: 'row',
                tickPosition: 'after',
                tickSize: 3,
                tickSpacing: 4,
                tickOverlap: false,
                tickFormat: '>-.2s',
                title: 'Value →',
                titleAlign: 'start',
                titleOffset: 4
            }
        ]}
    />
    </div>
    </>
)
export default MyResponsiveHeatMap;