import React from 'react';
import trabajando from "../images/mantenimiento.png";
const Mantenimiento = () => {
  return (
    <div style={{width: '100%', height: '100vh' }}>
      <img
        src={trabajando}  
        alt="Mantenimiento"
        className='mantenimiento-fondo'
      />
    </div>
  );
};

export default Mantenimiento;