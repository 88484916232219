import Navbar from "./Navbar";
import NavbarMini from "./NavbarMini";

function NavbarSelection({ location, signOut }) {
    if (location.pathname === '/Inbox') {
        return <NavbarMini signOut={signOut} />;
    } else {
      return <Navbar signOut={signOut} />;
    }
  }

  export default NavbarSelection;