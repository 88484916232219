export const mockDataTeam =[
  {
    "id": "chatbot",
    "data": [
      {
        "x": 8,
        "y": 0
      },
      {
        "x": 9,
        "y": 0
      },
      {
        "x": 10,
        "y": 0
      },
      {
        "x": 11,
        "y": 0
      },
      {
        "x": 12,
        "y": 0
      },
      {
        "x": 13,
        "y": 0
      },
      {
        "x": 14,
        "y": 0
      },
      {
        "x": 15,
        "y": 0
      },
      {
        "x": 16,
        "y": 0
      },
      {
        "x": 17,
        "y": 0
      },
      {
        "x": 18,
        "y": 0
      },
      {
        "x": 19,
        "y": 0
      },
      {
        "x": 20,
        "y": 0
      },
      {
        "x": 21,
        "y": 0
      },
      {
        "x": 22,
        "y": 0
      }
    ]
  },
  {
    "id": "user",
    "data": [
      {
        "x": 8,
        "y": 0
      },
      {
        "x": 9,
        "y": 0
      },
      {
        "x": 10,
        "y": 3
      },
      {
        "x": 11,
        "y": 1
      },
      {
        "x": 12,
        "y": 2
      },
      {
        "x": 13,
        "y": 0
      },
      {
        "x": 14,
        "y": 5
      },
      {
        "x": 15,
        "y": 0
      },
      {
        "x": 16,
        "y": 0
      },
      {
        "x": 17,
        "y": 0
      },
      {
        "x": 18,
        "y": 0
      },
      {
        "x": 19,
        "y": 0
      },
      {
        "x": 20,
        "y": 0
      },
      {
        "x": 21,
        "y": 0
      },
      {
        "x": 22,
        "y": 0
      }
    ]
  }
]
