import '../App.css';
import { HStack,VStack,Box } from '@chakra-ui/react';
import avatar from "../images/avatar.png";
import enojado from "../images/1_enojado.png";
import medio from "../images/2_medio.png";
import regular from "../images/3_regular.png";
import contento from "../images/4_contento.png";
import feliz from "../images/5_feliz.png";
import LineaGraph from "./LineaGraph.js"
import { useState } from 'react';
function TarjetasAnimo({ nombre, numero,id}) {
  const colores = [
    "#6FBF44",
    "#EFB025",
    "#F08036",
    "#EF4B3F",
    "#CB2334",
  ]
  const imagenes = [
    feliz,
    contento,
    regular,
    medio,
    enojado
  ]
  return (
    <HStack className='div-persona-venta'>
    <Box marginRight="10px">
      <img className="foto-usuario-overview" src={imagenes[id]} alt="foto-usuario"></img>
    </Box>
    <VStack spacing={"-1px"}>
        <p className='persona-overview-nombre'>{nombre}</p>
        <Box width="200px">
        <Box className="barra-fondo">
          <Box 
            className="barra-progreso"
            width={`${numero}%`}  
            style={{backgroundColor: colores[id]}} 
          />
        </Box>
        </Box>
    </VStack>
        <p className='porcentaje-progreso'>{numero}%</p>
    </HStack>
  );
}

export default TarjetasAnimo;