import "../App.css";
import { useState } from "react";
import { HStack, Spacer, VStack, Center, Button, Box } from "@chakra-ui/react";
import avatar from "../images/avatar.png";
import axios from "axios";
import { fetchAuthSession } from "@aws-amplify/auth";

function EtiquetaUsuario({ name, contact, active, setActive }) {
  const handleClick = async () => {
    const debtor = await axios.get(
      `https://api.pernexium.com/chatbot/api/debtor/telephone/${contact}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    const conversation = await axios.get(
      `https://api.pernexium.com/chatbot/api/conversation/debtor/${debtor.data.id}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    axios.patch(
      `https://api.pernexium.com/chatbot/api/conversation/${conversation.data.id}`,
      { botEnabled: !active },
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );
    setActive(!active);
  };
  return (
    <Center className="etiqueta-usuario">
      <HStack w="100%">
        <Box paddingLeft="20px">
          <img className="foto-usuario" src={avatar} alt="foto-usuario"></img>
        </Box>
        <VStack spacing="0px" justifyContent="flex-start" alignItems={"left"}>
          <p className="WHATSAPP-etiqueta">WHATSAPP</p>
          <p className="nombre-etiqueta">{name}</p>
        </VStack>
        <Spacer />
        <Center paddingRight="20px">
          <Button
            className={active ? "btn-enabled" : "btn-disabled"}
            onClick={handleClick}
          >
            {active ? "AI-Enabled" : "AI-Disabled"}
          </Button>
        </Center>
      </HStack>
    </Center>
  );
}

export default EtiquetaUsuario;
