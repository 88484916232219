import "../App.css";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HStack, Box, Center, Modal } from "@chakra-ui/react";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { parsePhoneNumber } from "../utils/auth";

const Backtesting = () => {
  const { id } = useParams();
  const [phoneNumber, setPhoneNumber] = useState("");

  const [messages, setMessages] = useState([]);
  const handleSend = async (value) => {
    const debtor = await axios.get(
      `https://api.pernexium.com/chatbot/api/debtor/telephone/${phoneNumber}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    const conversation = await axios.get(
      `https://api.pernexium.com/chatbot/api/conversation/debtor/${debtor.data.id}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    await axios.post(
      `https://api.pernexium.com/chatbot/api/message`,
      {
        role: "user",
        content: value,
        conversationId: conversation.data.id,
        senderId: phoneNumber,
        direction: "incoming",
      },
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    setMessages((prevMessages) => [
      ...prevMessages,
      { content: value, direction: "outgoing" },
    ]);
    const respuesta = await axios.post(
      "https://api.pernexium.com/openai/callOpenAI",
      {
        botName: id,
        telephone: phoneNumber,
        message: value,
      },
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    await axios.post(
      `https://api.pernexium.com/chatbot/api/message`,
      {
        role: "assistant",
        content: respuesta.data,
        conversationId: conversation.data.id,
        senderId: 0,
        direction: "outgoing",
      },
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    setMessages((prevMessages) => [
      ...prevMessages,
      { content: respuesta.data, direction: "incoming" },
    ]);
  };

  const deleteMessages = async (phone) => {
    const debtor = await axios.get(
      `https://api.pernexium.com/chatbot/api/debtor/telephone/${phone}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    const conversation = await axios.get(
      `https://api.pernexium.com/chatbot/api/conversation/debtor/${debtor.data.id}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    axios.delete(
      `https://api.pernexium.com/chatbot/api/message/conversation/${conversation.data.id}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );
  };

  useEffect(() => {
    parsePhoneNumber().then((phoneNumber) => {
      setPhoneNumber(phoneNumber);
      deleteMessages(phoneNumber);
    });
  }, []);

  return (
    <div style={{ position: "relative", height: "80vh", width: "67.8vw" }}>
      <MainContainer>
        <ChatContainer>
          <MessageList autoScrollToBottomOnMount={true}>
            {messages.map((message, index) => (
              <Message
                key={index}
                model={{
                  message: message.content,
                  direction: message.direction,
                }}
              />
            ))}
          </MessageList>
          <HStack
            as="MessageInput"
            w="100%"
            bg="#DEDFE4"
            spacing="0px"
            h="120px"
          >
            <HStack w="8%" marginLeft="8px" spacing="10px">
              <i className="material-icons btn-icon">attach_file</i>
              <i className="material-icons btn-icon">image</i>
            </HStack>
            <Box w="90%">
              <MessageInput
                attachButton={false}
                style={{
                  width: "100%",
                  border: "none",
                  backgroundColor: "#DEDFE4",
                }}
                onSend={handleSend}
                sendDisabled={false}
                fancyScroll={false}
              />
            </Box>
          </HStack>
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default Backtesting;
