import "../App.css";
import Overview from "../images/overview.png";
import Inbox from "../images/inbox.png";
import Chatbot from "../images/chabot.png";
import Campaigns from "../images/campaigns.png";
import Usage from "../images/usage.png";
import Setting from "../images/settings.png";
import Logout from "../images/logout.png";
import Logo from "../images/logo_navbar_delgado.png";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import React from "react";
import { Center, VStack } from "@chakra-ui/react";

function Navbar_delgada({ opcion, setOpcion, signOut }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedButtons, setSelectedButtons] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    // Actualizar el estado de los botones según la ubicación actual
    const currentPath = location.pathname;
    const newSelectedButtons = [false, false, false, false, false];

    if (currentPath === "/Overview") {
      newSelectedButtons[0] = true;
    } else if (currentPath === "/Inbox") {
      newSelectedButtons[1] = true;
    } else if (currentPath === "/Chatbot") {
      newSelectedButtons[2] = true;
    } else if (currentPath === "/Campaigns") {
      newSelectedButtons[3] = true;
    } else if (currentPath === "/") {
      newSelectedButtons[6] = true;
    }

    setSelectedButtons(newSelectedButtons);
  }, [location.pathname]);

  const handleClick = (index, path) => {
    setSelectedButtons(new Array(6).fill(false)); // Reiniciar todos los estados a false
    setSelectedButtons((prev) => ({ ...prev, [index]: true }));
    navigate(path);
  };
  return (
    <VStack className="dashboard-div-pernexium-delgado">
      <img
        src={Logo}
        alt="foto-Logo"
        className="dashboard-imagen-pernexium-delgado"
      />

      <button
        className={
          selectedButtons[0]
            ? "dashboard-boton-seleccionado-delgado"
            : "dashboard-boton-delgado"
        }
        onClick={() => handleClick(0, "/Overview")}
      >
        <Center h="100%" w="100%">
          <img src={Overview} alt="foto-overview" className="imagen-navbar-d" />
        </Center>
      </button>

      <button
        className={
          selectedButtons[1]
            ? "dashboard-boton-seleccionado-delgado"
            : "dashboard-boton-delgado"
        }
        onClick={() => handleClick(1, "/Inbox")}
      >
        <Center h="100%" w="100%">
          <img src={Inbox} alt="foto-Inbox" className="imagen-navbar-d" />
        </Center>
      </button>

      <button
        className={
          selectedButtons[2]
            ? "dashboard-boton-settings-seleccionado-delgado"
            : "dashboard-boton-settings-delgado"
        }
        onClick={() => handleClick(2, "/Chatbot")}
      >
        <Center h="100%" w="100%">
          <img src={Chatbot} alt="foto-Chatbot" className="imagen-navbar-d" />
        </Center>
      </button>

      <button
        className={
          selectedButtons[6]
            ? "dashboard-boton-seleccionado-logout-delgado"
            : "dashboard-boton-logout-delgado"
        }
        onClick={signOut}
      >
        <Center h="100%" w="100%">
          <img src={Logout} alt="foto-Setting" className="imagen-navbar-d" />
        </Center>
      </button>
    </VStack>
  );
}

export default Navbar_delgada;
