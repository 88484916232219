import Menu from "./menu.js";
import InfoContacto from "./InfoContacto.js";
import Mantenimiento from "../Pages/Mantenimiento.js";
import MenuOverview from "./MenuOverview.js";
import Privacidad from "../Pages/AvisoPrivacidad.js";
import Chatbot from "../Pages/Chatbot.js";
import Inbox from "../Pages/Inbox.js";
import Campaigns from "../Pages/Campaings.js";
// import CampaignUsuario from "../Pages/CampaignUsuario.js";
import MenuAnalitica from "./MenuAnalitica.js";
//import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
//import { AccountContext } from "./AccountContext";

import { HStack, Box } from "@chakra-ui/react";
import NavbarElection from "./NavbarSelection.js";
import { useLocation } from "react-router-dom";
function Views({ signOut, user }) {
  const location = useLocation();
  //const { loading } = useContext(AccountContext);
  return (
    <HStack spacing="0px" h="100vh" w="100vw">
      <Box>
        <NavbarElection location={location} signOut={signOut} />
      </Box>
      <Routes>
        <Route path="/" element={<MenuOverview />} />
        <Route path="/Privacidad" element={<Privacidad />} />

        <Route path="/menu/:id" element={<Menu />} />
        <Route path="/infoContacto" element={<InfoContacto />} />
        <Route path="/overview" element={<MenuOverview />} />
        <Route path="/analitica" element={<MenuAnalitica />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/chatbot" element={<Chatbot />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/settings" element={<Mantenimiento />} />
        <Route path="*" element={<Navigate to="/inbox" />} />
      </Routes>
    </HStack>
  );
}

export default Views;
