export const mockDataTeam = [
  {
    "country": "AD",
    "donut": 73,
  },
  {
    "country": "AE",
    "donut": 184,
  },
  {
    "country": "AF",
    "donut": 197,
  },
  {
    "country": "AG",
    "donut": 14,
  },
  {
    "country": "AI",
    "donut": 10,
  },
  {
    "country": "AL",
    "donut": 81,
  },
  {
    "country": "AM",
    "donut": 164,
  }
];