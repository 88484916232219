import { VStack,HStack } from '@chakra-ui/react'
import '../App.css';
import React, { useState } from "react";
function Contexto() {
  const [NombreOrganizacionInput, setNombreOrganizacionInput] = useState("");
  const [NombreOrganizacion, setNombreOrganizacion] = useState(null);

  const [DescripcionInput, setDescripcionInput] = useState("");
  const [Descripcion, setDescripcion] = useState(null);

  const [DiferenciadorInput, setDiferenciadorInput] = useState("");
  const [Diferenciador , setDiferenciador ] = useState(null);

  const [BeneficioInput, setBeneficioInput] = useState("");
  const [Beneficio , setBeneficio ] = useState(null);

  const handleCancel = () => {
    setNombreOrganizacionInput("");
    setNombreOrganizacion(null); 
    setDescripcionInput("");
    setDescripcion(null);
    setDiferenciadorInput("");
    setDiferenciador(null);
    setBeneficioInput("");
    setBeneficio(null);    
  };
  const handleNombreOrganizacionChange = (e) => {
    setNombreOrganizacionInput(e.target.value);
  };
  const handleDescripcionChange = (e) => {
    setDescripcionInput(e.target.value);
  };
  const handleDiferenciadorChange = (e) => {
    setDiferenciadorInput(e.target.value);
  };
  const handleBeneficioChange = (e) => {
    setBeneficioInput(e.target.value);
  };
  const handleUpload = () => {
    console.log(NombreOrganizacionInput);
    console.log(DescripcionInput);
    console.log(DiferenciadorInput);
    console.log(BeneficioInput);
  };
  return (
    <HStack>
      <VStack justifyContent='flex-start' alignItems={"left"} spacing={"35px"}>
        <p className='info-chatbot'>Contexto de la organización</p>
        <p className='contexto-subtitulo'>Nombre de la organización</p>
        <p className='contexto-texto'>Name of your business or organization.</p>
        <p className='contexto-subtitulo'>Descripción de la organización</p>
        <p className='contexto-texto'>A short but concise description of your business or organization and what you do.</p>
        <p className='contexto-subtitulo'>Diferenciador de la organización</p>
        <p className='contexto-texto'>Make sure your bot knows why clients should choose you.</p>
        <p className='contexto-subtitulo'>Beneficio del bot para la organización</p>
        <p className='contexto-texto'>This can go from answering questions for customers to almost anything.</p>
      </VStack>
    <VStack className="div-inputs" justifyContent='flex-start' alignItems={"left"}  spacing={"50px"} marginTop={"49px"}>
      <input type="text" className='nombre-organizacion-input'
        placeholder={NombreOrganizacion ? NombreOrganizacion.toLocaleDateString() : "Escribe el nombre de la organizacion"}
        value={NombreOrganizacionInput}
        onChange={handleNombreOrganizacionChange}></input>

      <input type="text" className='input-contexto'
        placeholder={Descripcion ? Descripcion.toLocaleDateString() : "Escribe la descripción de la organización..."}
        value={DescripcionInput}
        onChange={handleDescripcionChange}></input>

      <input type="text" className='input-contexto'
        placeholder={Diferenciador ? Diferenciador.toLocaleDateString() : "Escribe el diferenciador de la organización..."}
        value={DiferenciadorInput}
        onChange={handleDiferenciadorChange}></input>
      <input type="text" className='input-contexto'       
        placeholder={Beneficio ? Beneficio.toLocaleDateString() : "Escribe el beneficio que obtiene la organización del bot..."}
        value={BeneficioInput}
        onChange={handleBeneficioChange}></input>
      <HStack spacing={"120px"} marginTop={"-20px"}>
      <button className='archivo' onClick={handleUpload}>Guardar</button>
      <button className="cancelar" onClick={handleCancel}>Cancelar</button>
      </HStack>
    </VStack>
    </HStack>
  );
}

export default Contexto;