export const mockDataTeam = [
  {
      "id": "Lunes",
      "data": [
          {
              "x": "08:00-10:00",
              "y": 0
          },
          {
              "x": "10:00-12:00",
              "y": 0
          },
          {
              "x": "12:00-14:00",
              "y": 0
          },
          {
              "x": "14:00-16:00",
              "y": 0
          },
          {
              "x": "16:00-18:00",
              "y": 0
          },
          {
              "x": "18:00-20:00",
              "y": 0
          },
          {
              "x": "20:00-22:00",
              "y": 0
          },
          {
              "x": "22:00-00:00",
              "y": 0
          }
      ]
  },
  {
      "id": "Martes",
      "data": [
          {
              "x": "08:00-10:00",
              "y": 0
          },
          {
              "x": "10:00-12:00",
              "y": 0
          },
          {
              "x": "12:00-14:00",
              "y": 0
          },
          {
              "x": "14:00-16:00",
              "y": 0
          },
          {
              "x": "16:00-18:00",
              "y": 0
          },
          {
              "x": "18:00-20:00",
              "y": 0
          },
          {
              "x": "20:00-22:00",
              "y": 0
          },
          {
              "x": "22:00-00:00",
              "y": 0
          }
      ]
  },
  {
      "id": "Miércoles",
      "data": [
          {
              "x": "08:00-10:00",
              "y": 0
          },
          {
              "x": "10:00-12:00",
              "y": 0
          },
          {
              "x": "12:00-14:00",
              "y": 0
          },
          {
              "x": "14:00-16:00",
              "y": 0
          },
          {
              "x": "16:00-18:00",
              "y": 0
          },
          {
              "x": "18:00-20:00",
              "y": 0
          },
          {
              "x": "20:00-22:00",
              "y": 0
          },
          {
              "x": "22:00-00:00",
              "y": 0
          }
      ]
  },
  {
      "id": "Jueves",
      "data": [
          {
              "x": "08:00-10:00",
              "y": 0
          },
          {
              "x": "10:00-12:00",
              "y": 0
          },
          {
              "x": "12:00-14:00",
              "y": 0
          },
          {
              "x": "14:00-16:00",
              "y": 0
          },
          {
              "x": "16:00-18:00",
              "y": 0
          },
          {
              "x": "18:00-20:00",
              "y": 0
          },
          {
              "x": "20:00-22:00",
              "y": 0
          },
          {
              "x": "22:00-00:00",
              "y": 0
          }
      ]
  },
  {
      "id": "Viernes",
      "data": [
          {
              "x": "08:00-10:00",
              "y": 0
          },
          {
              "x": "10:00-12:00",
              "y": 0
          },
          {
              "x": "12:00-14:00",
              "y": 0
          },
          {
              "x": "14:00-16:00",
              "y": 0
          },
          {
              "x": "16:00-18:00",
              "y": 0
          },
          {
              "x": "18:00-20:00",
              "y": 0
          },
          {
              "x": "20:00-22:00",
              "y": 0
          },
          {
              "x": "22:00-00:00",
              "y": 0
          }
      ]
  },
  {
      "id": "Sábado",
      "data": [
          {
              "x": "08:00-10:00",
              "y": 0
          },
          {
              "x": "10:00-12:00",
              "y": 0
          },
          {
              "x": "12:00-14:00",
              "y": 0
          },
          {
              "x": "14:00-16:00",
              "y": 0
          },
          {
              "x": "16:00-18:00",
              "y": 0
          },
          {
              "x": "18:00-20:00",
              "y": 0
          },
          {
              "x": "20:00-22:00",
              "y": 0
          },
          {
              "x": "22:00-00:00",
              "y": 0
          }
      ]
  },
  {
      "id": "Domingo",
      "data": [
          {
              "x": "08:00-10:00",
              "y": 0
          },
          {
              "x": "10:00-12:00",
              "y": 0
          },
          {
              "x": "12:00-14:00",
              "y": 0
          },
          {
              "x": "14:00-16:00",
              "y": 0
          },
          {
              "x": "16:00-18:00",
              "y": 0
          },
          {
              "x": "18:00-20:00",
              "y": 0
          },
          {
              "x": "20:00-22:00",
              "y": 0
          },
          {
              "x": "22:00-00:00",
              "y": 0
          }
      ]
  }
]
