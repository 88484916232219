import "../App.css";
import React from "react";
import { Center } from "@chakra-ui/react";
import Popup from "./Popupchatbot.js";

function chatbot() {
  return (
    <Center className="etiqueta">
      <Popup />
    </Center>
  );
}

export default chatbot;
