import { fetchUserAttributes } from "aws-amplify/auth";

export const parsePhoneNumber = async () => {
    const userAttributes = await fetchUserAttributes();
    // Return the phone number in the format 521**********
    // We need to remove the + from the phone number and add a 1 between the country code and the phone number
    let phoneNumber = userAttributes.phone_number;
    console.log('phoneNumber', phoneNumber)
    phoneNumber = phoneNumber.replace("+52", "521");
    return phoneNumber;
}