import './App.css';
import Views from './Components/Views';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import config from './amplifyconfiguration.json';
import { Authenticator, useTheme } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify/utils';
import { cognitoVocabulary } from './utils/vocabularies';
import { Image, View, Text } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

Amplify.configure(config);

I18n.setLanguage('es');

I18n.putVocabulariesForLanguage('es', cognitoVocabulary.es);

function App() {

  const navigate = useNavigate();

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View width='100%' textAlign="center" padding={tokens.space.large}>
          <Image
            alt="Pernexium logo"
            src="logo192.png"
            width='100px'
          />
        </View>
      );
    },
    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.white} onClick={() => { navigate('/privacidad') }}>
            Aviso de privacidad
          </Text>
        </View>
      );
    }
  }

  const formFields = {
    signUp: {
      'custom:enterpriseId': {
        label: 'Empresa',
        placeholder: 'Nombre de tu empresa',
        required: true,
      },
    }
  };
  return (
    <div id='pernexiumApp'>
      <Authenticator formFields={formFields} components={components} variation='modal' >
        {({ signOut, user }) => (
          <Views signOut={signOut} user={user} />
        )}
      </Authenticator>
    </div>
  );
}

export default App;