import "../App.css";
import avatar from "../images/avatarchatbot.png";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  VStack,
  HStack,
} from "@chakra-ui/react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import axios from "axios";
import { CHATBOT_API_URL } from "../Constants";
import { fetchAuthSession } from "@aws-amplify/auth";
import { parsePhoneNumber } from "../utils/auth";

function Popupchatbot() {
  const [files, setFiles] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    parsePhoneNumber().then((phoneNumber) => {
      setPhoneNumber(phoneNumber);
    });
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const inputRef = useRef();
  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };
  //send files to the server
  const handleUpload = () => {};

  const navigate = useNavigate();

  const [nombreChatbot, setNombreChatbot] = useState("");
  const handleInputChange = (event) => {
    setNombreChatbot(event.target.value);
  };

  // Función para manejar la obtención del valor del input
  const obtenerValorInput = async () => {
    if (nombreChatbot.length === 0) {
      console.log("error el nombre debe incluir algo");
      return;
    } else {
      axios.post(
        `https://sls-chatbot.pernexium.com/v1/bots/create`,
        {
          name: nombreChatbot,
          phone: phoneNumber,
        },
        {
          headers: {
            Authorization:
              "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
          },
        }
      );
      navigate(`/Menu/${nombreChatbot}`);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen}>+ New Chatbot</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crea tu chatbot</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack spacing={"40px"}>
              <VStack alignItems="flex-start">
                <img
                  className="foto-usuario-modal"
                  src={avatar}
                  alt="foto-usuario"
                ></img>
                <div
                  className="subir-foto"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    accept=".jpg"
                    multiple
                    onChange={(event) => setFiles(event.target.files)}
                    hidden
                    ref={inputRef}
                  />
                  <Button
                    onClick={() => inputRef.current.click()}
                    className="subir-foto"
                  >
                    Subir foto
                  </Button>
                </div>
              </VStack>
              <VStack alignItems="flex-start">
                <Text className="titulos-modal">Nombre</Text>
                <input
                  className="nombre-chatbot-modal"
                  placeholder="Nombre del chatbot"
                  value={nombreChatbot}
                  onChange={handleInputChange}
                />
                <Text className="titulos-modal">ID Público</Text>
                <Text className="id-modal">c8b8f315-5602</Text>
              </VStack>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={obtenerValorInput}>
              Crear Chatbot
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Popupchatbot;
