import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'Manrope',
    body: 'Manrope',
    bebasNeue: '"Bebas Neue", sans-serif',
  },
  fontSizes: {
    '3xs': '10px',
    xs: '12px',
    mini: '15px',
  },
  colors: {
    white: '#fff',
    black: '#000',
    steelblue100: '#4177b6',
    gray: '#787878',
  },
  space: {
    mini: '15px',
    '11xl': '30px',
  },
  radii: {
    '11xl': '30px',
  },
});

export default theme;