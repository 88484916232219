import { VStack, HStack } from "@chakra-ui/react";
import Drag from "../Components/Dragdrop.js";
import { useState, useEffect } from "react";
import { CHATBOT_API_URL } from "../Constants.js";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Textarea } from "@chakra-ui/react";

function Conocimiento() {
  const { id } = useParams();
  const [baseConocimiento, setBaseConocimiento] = useState("");

  useEffect(() => {
    fetchAuthSession().then((data) => {
      axios
        .get(`${CHATBOT_API_URL}/bot/name/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + data.tokens.idToken.toString(),
          },
        })
        .then((res) => {
          setBaseConocimiento(res.data.businessRules);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }, []);

  const onSubmit = async () => {
    const url = `${CHATBOT_API_URL}/bot/uploadBusinessRules`;
    await axios.post(
      url,
      {
        name: id,
        businessRules: baseConocimiento,
      },
      {
        headers: {
          Authorization: `Bearer ${(
            await fetchAuthSession()
          ).tokens.idToken.toString()}`,
          "Content-Type": "application/json",
        },
      }
    );
  };
  return (
    <>
      <VStack spacing={"20px"}>
        <HStack marginRight={"auto"}>
          <p className="info-chatbot">Base de conocimiento</p>
        </HStack>
        <HStack>
          <VStack className="conocimiento-div1">
            <div className="div-conocimiento-espaciado">
              <p className="titulo2-conocimiento">
                Subir una base de conocimiento
              </p>
              <p className="titulo-conocimiento">
                Su chatbot podrá utilizar la información de su base de
                conocimientos para responder a las preguntas con precisión.
              </p>
            </div>
            <div className="div-dragespacio">
              <Textarea
                width="33vw"
                height="46.52vh"
                resize="none"
                value={baseConocimiento}
                onChange={(e) => setBaseConocimiento(e.target.value)}
              />

              <HStack marginTop={"20px"} justifyContent={"center"}>
                <button className="archivo" onClick={onSubmit}>
                  Guardar
                </button>
                <button className="cancelar">Cancelar</button>
              </HStack>
            </div>
          </VStack>

          <VStack className="conocimiento-div1">
            <div className="div-conocimiento-espaciado">
              <p className="titulo2-conocimiento">
                Subir una lista de deudores
              </p>
              <p className="titulo-conocimiento">
                Su chatbot podrá contactar a las personas que se encuentren en
                la lista.
              </p>
            </div>
            <div className="div-dragespacio">
              <Drag />
            </div>
          </VStack>
        </HStack>
      </VStack>
    </>
  );
}

export default Conocimiento;
/*<Drag/>*/
