import "../App.css";
import { useEffect, useState } from "react";
import Conversacion from "./Conversacion.js";
import EtiquetaUsuario from "./EtiquetaUsuario.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Button,
  useDisclosure,
  Center,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { CHATBOT_API_URL } from "../Constants";
import { fetchAuthSession } from "@aws-amplify/auth";

function ConversacionJunto({ name, contact }) {
  const [active, setActive] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [messages, setMessages] = useState([]);
  const [tempValue, setTempValue] = useState("");
  const [count, setCount] = useState(0);

  const fetchDebtorMessage = async () => {
    try {
      const response = await fetch(
        `${CHATBOT_API_URL}/debtor/messages/${contact}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
          },
        }
      );

      if (!response.ok || response.status >= 400) {
        return;
      }

      const data = await response.json();

      await Promise.all(
        data.map(async (mensaje) => {
          if (!mensaje.seen) {
            return await axios.patch(
              `${CHATBOT_API_URL}/message/${mensaje.id}`,
              { seen: true },
              {
                headers: {
                  Authorization:
                    "Bearer " +
                    (await fetchAuthSession()).tokens.idToken.toString(),
                },
              }
            );
          }
          return mensaje;
        })
      );

      setMessages(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchStatus = async () => {
    const debtor = await axios.get(
      `https://sls-chatbot.pernexium.com/messages/${contact}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    const conversation = await axios.get(
      `https://api.pernexium.com/chatbot/api/conversation/debtor/${debtor.data.id}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    setActive(conversation.data.botEnabled);
  };

  const handleChange = async () => {
    const debtor = await axios.get(
      `https://sls-chatbot.pernexium.com/messages/${contact}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    const conversation = await axios.get(
      `https://api.pernexium.com/chatbot/api/conversation/debtor/${debtor.data.id}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    axios.patch(
      `https://api.pernexium.com/chatbot/api/conversation/${conversation.data.id}`,
      { botEnabled: !active },
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    setActive(!active);
    onClose();

    const session = await axios.get(
      `https://api.pernexium.com/chatbot/api/session/${conversation.data.sessionId}`,
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    let recipientPhone = contact.slice(0, 2) + contact.slice(3);
    let data = JSON.stringify({
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: recipientPhone,
      type: "text",
      text: {
        preview_url: false,
        body: tempValue,
      },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://graph.facebook.com/v18.0/${session.data.id}/messages`,
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer EAAOZCtQ6khoABO2EXZCDhg0FRygEHNNzBwmeQttOk0VZBuA6BiTYib1Or9gaS04qmf1owZC0y63xC8TZBO5sGbUMGDgQrPjMvI78aXCLB0WQIJzpqfyIP3Ab63WJlRNafcBZA7hiBkcZCSFow1wk9YJYIxfsKdzEfOiZB6EpOFkQqmZBYNqxor45LZAsS5VonqPwatZCDWsyrzqKtzAXB2r",
        Cookie: "ps_l=0; ps_n=0",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

    await axios.post(
      `https://api.pernexium.com/chatbot/api/message`,
      {
        role: "user",
        content: tempValue,
        conversationId: conversation.data.id,
        senderId: contact,
        direction: "outgoing",
        seen: 1,
      },
      {
        headers: {
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      }
    );

    const newMessageKey = `message${Object.keys(messages).length + 1}`;
    const newMessage = {
      content: tempValue,
      direction: "outgoing",
      createdAt: new Date().toISOString(),
    };

    setMessages((prevMessages) => ({
      ...prevMessages,
      [newMessageKey]: newMessage,
    }));
  };

  useEffect(() => {
    fetchDebtorMessage();
    fetchStatus();
  }, [name]);

  useEffect(() => {
    if (count === 5) {
      setCount(0);
      fetchDebtorMessage();
    }

    const timeoutId = setTimeout(() => {
      setCount(count + 1);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [count]);

  return (
    <>
      <VStack width="100vh" height="100vh" spacing={"0px"}>
        <EtiquetaUsuario
          name={name}
          active={active}
          contact={contact}
          setActive={setActive}
        />
        <Conversacion
          contact={contact}
          active={active}
          setActive={setActive}
          onOpen={onOpen}
          messages={messages}
          setMessages={setMessages}
          setTempValue={setTempValue}
        />
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent marginTop="200px">
          <ModalHeader>AI-Enabled</ModalHeader>
          <ModalCloseButton />
          <ModalBody>¿Desea desactivar a la IA?</ModalBody>

          <ModalFooter>
            <Center w="100%">
              <Button colorScheme="green" mr={3} onClick={handleChange}>
                Desactivar
              </Button>
              <Button colorScheme="red" mr={3} onClick={onClose}>
                Cerrar
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConversacionJunto;
