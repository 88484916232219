import '../App.css';
import { HStack,VStack } from '@chakra-ui/react';
import TarjetaReporte from '../Components/TarjetaReporte';
import React from 'react';
function Reporte() {

  const tarjetas = [
    { titulo:"Tiempo de Respuesta y Duración de Conversaciones",valor1:"Día de mayor contactación",valor2:"Nivel de satisfacción",valor3:"Palabra más usada"},
    { titulo:"Participación por Roi",valor1:"Número de ventas",valor2:"Ingresos totales",valor3:"Porcentaje de contactación"},
    { titulo:"Reporte3",valor1:"valor1",valor2:"valor2",valor3:"valor3"}]
    const hStacks = [];

    for (let i = 0; i < tarjetas.length; i += 2) {
      const studentsInRow = tarjetas.slice(i, i + 2);
      const hStack = (
        <HStack className='div-reporte' justifyContent="space-between" marginTop="50px">
          {studentsInRow.map((tarjetas) => (
            <TarjetaReporte
              titulo={tarjetas.titulo}
              valor1={tarjetas.valor1}
              valor2={tarjetas.valor2}
              valor3={tarjetas.valor3}
              mostrarTexto={true}
            />
            
          ))}
        </HStack>
      );
      hStacks.push(hStack);
  
    }
    return (
    <VStack alignItems={"flex-start"}>
      {hStacks}
    </VStack>
  );
}

export default Reporte;
