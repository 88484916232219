import "../App.css";
import { HStack, VStack } from "@chakra-ui/react";
import TarjetasAnimo from "../Components/TarjetasAnimo";
import Cloud from "../images/Cloud.png";
//import Word from "../Components/WordCloud";
function TemperaturaConversaciones() {
  const PersonaVentas = [
    { nivel: "Muy Satisfecho", porcentaje: 50, id: 0 },
    { nivel: "Satisfecho", porcentaje: 30, id: 1 },
    { nivel: "Neutral", porcentaje: 10, id: 2 },
    { nivel: "Poco satisfecho", porcentaje: 5, id: 3 },
    { nivel: "Nada satisfecho", porcentaje: 5, id: 4 },
  ];
  const vStacks = [];
  for (let i = 0; i < PersonaVentas.length; i += 5) {
    const personasenfila = PersonaVentas.slice(i);
    const vStack = (
      <VStack spacing="30px" marginTop={"10px"}>
        {personasenfila.map((ventas) => (
          <TarjetasAnimo
            nombre={ventas.nivel}
            numero={ventas.porcentaje}
            id={ventas.id}
            mostrarTexto={true}
          />
        ))}
      </VStack>
    );
    vStacks.push(vStack);
  }
  return (
    <HStack marginTop={"20px"}>
      <div className="div-nube-palabras">
        <img src={Cloud} />
      </div>
      <div className="div-ventas-overview">
        <p>Últimas conversaciones</p>
        <p className="temperatura-texto">
          El promedio de temperatura de las últimas conversaciones es:
        </p>
        {vStacks}
      </div>
      <div></div>
    </HStack>
  );
}

export default TemperaturaConversaciones;
