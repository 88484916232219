import '../App.css';
import { HStack,VStack,Box } from '@chakra-ui/react';
function TarjetasOverview({ titulo, message, aumento,imagen }) {

  return (
        <div className='overview-contenedor-tarjeta'>
        <VStack alignItems='flex-start' marginLeft={"10px"}>
            <HStack width={"97%"} marginTop={"5px"} justifyContent="space-between">
                <p className='texto-overview-titulo'>{titulo}</p>
                <img src={imagen} alt="Imagen-icono"></img>
            </HStack>
            <p className='texto-overview-message'>{message}</p>
            <p className='texto-overview-aumento'>{aumento}% más que el mes pasado</p>
        </VStack>
        </div>
  );
}

export default TarjetasOverview;
