import '../App.css';
import React from 'react';
import { Button, HStack, VStack } from '@chakra-ui/react';
function BotonesOverview({opcion,setOpcion}) {

  return (
  <VStack>
    <HStack>
        <Button marginLeft={"28px"} className={opcion===0 ? 'boton-overview-seleccionado':'boton-overview'} onClick={() =>{setOpcion(0)}}>Overview</Button>
        <Button className={opcion===1 ? 'boton-overview-seleccionado':'boton-overview'} onClick={() =>{setOpcion(1)}}>Analítica</Button>
        <Button className={opcion===2 ? 'boton-overview-seleccionado':'boton-overview'} onClick={() =>{setOpcion(2)}}>Reporte</Button>
    </HStack>
  </VStack>
  );
}

export default BotonesOverview;