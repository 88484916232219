import flecha from "../images/arrow.png";
import {Center } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import '../App.css';

function Botonregresar() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Chatbot");
  };        
  return (
<button className='boton-regresar' onClick={() => handleClick()}>
  <Center>
    <img className="imagen-boton-regresar" src={flecha} alt="Flecha de regreso" />
    Regresar
  </Center>
</button>
  );
}

export default Botonregresar;