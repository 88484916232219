import "../App.css";
import React, { useState, useEffect } from "react";
import { VStack, HStack, Box } from "@chakra-ui/react";
import MessageInbox from "../Components/MessageInbox.js";
import InfoContacto from "../Components/InfoContacto.js";
import ConversacionJunto from "../Components/ConversacionJunto.js";
import Conversacionvacia from "../Components/Conversacionvacia.js";
import List from "../images/list.png";
import Popupfiltrochat from "../Components/Popupfiltrochat.js";
import axios from "axios";
import { CHATBOT_API_URL } from "../Constants";
import { fetchAuthSession } from "@aws-amplify/auth";
import NuevoMensaje from "../Components/NuevoMensaje/NuevoMensaje.jsx";

const Inbox = () => {
  const [personas, setPersonas] = useState([]);
  const [conversacionPersona, setConversacionPersona] = useState(null);
  const [count, setCount] = useState(0);

  const fetchDebtors = () => {
    fetchAuthSession().then((data) => {
      axios
        .get(`${CHATBOT_API_URL}/debtor/ordered`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + data.tokens.idToken.toString(),
          },
        })
        .then(async (res) => {
          const newPersonas = res.data.map((persona) => ({
            nombre: `${persona.firstName1} ${persona.lastName1}`,
            contact: persona.phone1,
            createdAt: persona.latest_message_time,
            unseen: persona.unseen_messages_count,
          }));
          setPersonas(newPersonas);
        });
    });
  };

  useEffect(() => {
    fetchDebtors();
  }, []);

  useEffect(() => {
    if (count === 30) {
      setCount(0);
      fetchDebtors();
    }

    const timeoutId = setTimeout(() => {
      setCount(count + 1);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [count]);

  const hStacks = personas.map((persona, index) => {
    return (
      <Box
        onClick={() => {
          setConversacionPersona(persona);
        }}
        w="100%"
        key={index}
      >
        <MessageInbox
          nombre={persona.nombre}
          contact={persona.contact}
          unseen={persona.unseen}
          createdAt={persona.createdAt}
        />
      </Box>
    );
  });

  const [filtered, setFiltered] = useState(hStacks);

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toLowerCase();

    const filtered = hStacks.filter((element) => {
      const nombre =
        element.type === "MessageInbox"
          ? element.props.nombre.toLowerCase()
          : element.props.children.props.nombre.toLowerCase();

      return nombre.includes(inputValue);
    });
    if (filtered.length > 0) {
      setShowAll(false);
    }
    setFiltered(filtered);
  };

  const handleInputChangeNumber = (event) => {
    const inputValue = event.target.value.toLowerCase();

    const filtered = hStacks.filter((element) => {
      const nombre =
        element.type === "MessageInbox"
          ? element.props.contact.toLowerCase()
          : element.props.children.props.contact.toLowerCase();

      return nombre.includes(inputValue);
    });
    if (filtered.length > 0) {
      setShowAll(false);
    }
    setFiltered(filtered);
  };
  const [showAll, setShowAll] = useState(true);

  return (
    <HStack spacing={"0px"} h="100vh" w="100%">
      <VStack
        spacing={"0px"}
        overflowX="hidden"
        overflowY="scroll"
        h="100vh"
        w="27%"
        borderLeft="1px solid #a0a0a0"
        borderRight="1px solid #a0a0a0"
      >
        <VStack className="messagebox" spacing={"12px"}>
          <HStack marginTop={"10px"}>
            <p className="message-inbox-text">Inbox de mensajes</p>
          </HStack>
          <HStack alignItems="flex-end">
            <input
              className="inbox-input"
              onChange={handleInputChange}
              style={{ textIndent: "10px" }}
              placeholder="Nombre"
            ></input>

            <button
              className="botones-message-filtro"
              style={{ backgroundImage: `url(${List})` }}
            >
              <Popupfiltrochat />
            </button>
            <NuevoMensaje fetchDebtors={fetchDebtors} />
          </HStack>
          <input
            className="inbox-input-n"
            onChange={handleInputChangeNumber}
            style={{ textIndent: "10px" }}
            placeholder="Número"
          ></input>
        </VStack>
        {showAll && hStacks}

        {!showAll && filtered.length > 0 && filtered}

        {!showAll && filtered.length === 0 && <p>No hay coincidencia</p>}
      </VStack>
      {conversacionPersona ? (
        <>
          <ConversacionJunto
            name={conversacionPersona.nombre}
            contact={conversacionPersona.contact}
          />
          <InfoContacto
            name={conversacionPersona.nombre}
            contact={conversacionPersona.contact}
          />
        </>
      ) : (
        <Conversacionvacia />
      )}
    </HStack>
  );
};

export default Inbox;
