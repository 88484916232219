import "../App.css";
import React from "react";
import Drop from "../images/upload.png";
import { HStack, VStack } from "@chakra-ui/react";
import { useRef, useState } from "react";
import axios from "axios";
import { CHATBOT_API_URL } from "../Constants";
import { fetchAuthSession } from "@aws-amplify/auth";
import { useParams } from "react-router-dom";

const DragDropFiles = () => {
  const [files, setFiles] = useState(null);

  // Get bot name from url params
  const { id } = useParams();

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const inputRef = useRef();

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };
  //send files to the server
  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("botName", id);
  
      const response = await axios.post(`https://sls-chatbot.pernexium.com/v1/upload-csv`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
        },
      });

      if (response.status >= 200 && response.status < 300) {
        console.log("Carga exitosa del CSV");
      } else {
        console.error("Error al cargar el CSV. Código de estado:", response.status);
      }
    } catch (error) {
      console.error("Error al subir el archivo:", error);
    }
  };
  

  if (files)
    return (
      <>
        <div className="dragdrop">
          <ul className="mapfiles">
            {Array.from(files).map((file, idx) => (
              <li key={idx}>{file.name}</li>
            ))}
          </ul>
        </div>
        <HStack>
          <button onClick={handleUpload} className="archivo">
            Guardar
          </button>
          <button onClick={() => setFiles(null)} className="cancelar">
            Cancelar
          </button>
        </HStack>
      </>
    );
  return (
    <>
      {!files && (
        <div
          className="dragdrop"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            type="file"
            accept=".xlsx"
            multiple
            onChange={(event) => setFiles(event.target.files)}
            hidden
            ref={inputRef}
          />
          <button
            onClick={() => inputRef.current.click()}
            className="titulo-conocimiento"
          >
            <VStack>
              <img src={Drop} alt="foto-drop"></img>
              <p>
                Arrastre aquí un archivo para cargarlo, o haga clic aquí para
                adjuntarlo.
              </p>
            </VStack>
          </button>
        </div>
      )}
      <HStack marginTop={"20px"} justifyContent={"center"}>
        <button onClick={handleUpload} className="archivo">
          Guardar
        </button>
        <button onClick={() => setFiles(null)} className="cancelar">
          Cancelar
        </button>
      </HStack>
    </>
  );
};

export default DragDropFiles;
