import "../App.css";
import user from "../images/avatar.png";
import flecha from "../images/Vector.png";
import puntos from "../images/3puntos.png";
import { HStack, VStack, Box, Text, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EditaChatbot from "../Components/EditaChatbot";
import { CHATBOT_API_URL } from "../Constants";
import { fetchAuthSession } from "@aws-amplify/auth";
import axios from "axios";

function Etiqueta(props) {
  const navigate = useNavigate();
  const handleClickeditar = (e) => {
    console.log("editar");
    e.stopPropagation();
    
  };
  const handleEliminar = async (e) => {
    axios.delete(`${CHATBOT_API_URL}/bot/name/${props.nombre}`, {
      headers: {
        Authorization:
          "Bearer " + (await fetchAuthSession()).tokens.idToken.toString(),
      },
    });
    navigate("/Chatbot");
  };

  function handleClick() {
    navigate(`/Menu/${props.nombre}`);
    console.log(props);
  }

  const [showEdit, setShowEdit] = useState(false);

  const Bolita = () => {
    const [estilos] = useState({
      width: "12px",
      height: "12px",
      backgroundColor: "#27852C",
      borderRadius: "50%",
      marginLeft: "50px", 
      position: "absolute", // Cambia la posición a absolute

    });

    return <div style={estilos}></div>;
  };
  return (
    <HStack spacing="10px" className="etiqueta" onClick={handleClick}>
        <Box marginRight="10px" marginLeft="25px">
          <img className="foto-usuario" src={user} alt="foto-usuario"></img>
        </Box>

        <VStack spacing="20px" justifyContent="flex-start" alignItems={"left"}>
          <Text className="textonombre">{props.nombre}</Text>
          <HStack marginTop={-25}>
            <Bolita />
            <Text className="status">{props.status}</Text>
          </HStack>
        </VStack>
      <Button className="boton-chatbot">
        <img src={puntos} alt="regresar" className="editar-chatbot"></img>
        <div className="content">
          <p onClick={handleEliminar}>Eliminar</p>
        </div>
      </Button>
    </HStack>
  );
}

export default Etiqueta;

