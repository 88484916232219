import '../App.css';
import { HStack,VStack } from '@chakra-ui/react';
import React from 'react';
function TarjetaReporte({titulo,valor1,valor2,valor3}) {
    const onButtonClick = () => {
     
        // using Java Script method to get PDF file
        fetch("SamplePDF.pdf").then((response) => {
            response.blob().then((blob) => {
             
                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);
                     
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "SamplePDF.pdf";
                alink.click();
            });
        });
    };
    return (
    <VStack className='tarjeta-reporte' spacing={"20px"}>
        <p className='titulo-reporte-texto'>{titulo}</p>
        <HStack w="100%"  className='texto-reporte'>
            <p className='tarjeta-reporte-p'>{valor1}</p>
            <p className='tarjeta-reporte-p-2'>{valor2}</p>
            <p className='tarjeta-reporte-p'>{valor3}</p>
        </HStack>
        <button className='boton-reporte-descagar' onClick={onButtonClick}>Descargar</button>
    </VStack>
  );
}

export default TarjetaReporte;
