import '../App.css';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button, 
  useDisclosure,
  Text,VStack, HStack,Checkbox,CheckboxGroup,Stack  } from "@chakra-ui/react";
import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import List from "../images/list.png"
function Popupfiltrochat() {

    const navigate = useNavigate();

    const [nombreChatbot, setNombreChatbot] = useState('');
    const handleInputChange = (event) => {
      setNombreChatbot(event.target.value);
    };
  
    // Función para manejar la obtención del valor del input
    const obtenerValorInput = () => {
      console.log(nombreChatbot);
      navigate(`/Inbox`);
    };

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button  onClick={onOpen}></Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filtros de chat</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={"10px"}  marginLeft={"0px"} alignItems="flex-start">
                <HStack>
                    <VStack>
                    <p>Fecha</p>
                    <input className='nombre-chatbot-modal' placeholder='XX/XX/XX'></input>
                    </VStack>
                </HStack>
                <Text>Tags</Text>
                <Stack spacing={"20px"} className="filtro-checkbox">
                        <Checkbox value="Listo para pagar" className='filtrochat'>Listo para pagar</Checkbox>
                        <Checkbox className="filtro-checkbox" value="Mostrar conversaciones sin contestar">Mostrar conversaciones sin contestar</Checkbox>
                </Stack>
            </VStack>
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}


export default Popupfiltrochat;
