import user from "../images/avatar.png";
import Chatbotimage from "../images/avatarchatbot.png";
import message from "../images/BiMessageDetail.png";
import { VStack, HStack } from "@chakra-ui/react";
import "../App.css";
function InfoContacto({ name, contact }) {
  return (
    <div className="info-contacto-div-grande">
      <VStack spacing="20px" paddingTop="20px">
        <p className="info-contacto-info">Chatbot responsable</p>
        <img
          className="foto-usuario-chatbot"
          src={Chatbotimage}
          alt="foto-usuario"
        ></img>
        <p className="info-contacto-info">props.chatbot</p>
        <p className="info-contacto-info">Información de Contacto</p>
        <div className="info-contacto-div">
          <HStack marginLeft={"10px"} marginTop={"10px"}>
            <img
              className="foto-usuario-contacto"
              src={user}
              alt="foto-usuario"
            ></img>
            <p className="info-contacto-nombre">{name}</p>
          </HStack>
          <p className="info-contacto-numero">{contact}</p>
        </div>
      </VStack>
      <VStack
        marginTop={"20px"}
        spacing={"5px"}
        className="texto-info-contacto"
      >
        <p className="info-contacto-texto">AI ToolKit</p>
        <button className="info-contacto-boton-resumen">
          RESUMEN DE LA CONVERSACIÓN
        </button>
      </VStack>
      <VStack
        marginTop={"20px"}
        spacing={"5px"}
        className="texto-info-contacto"
      >
        <p className="info-contacto-texto">Etiquetas</p>
        <button className="info-contacto-boton-etiqueta">ETIQUETA</button>
      </VStack>
      <VStack
        marginTop={"20px"}
        spacing={"5px"}
        className="texto-info-contacto"
      >
        <p className="info-contacto-texto">Detalles</p>
        <HStack>
          <button className="fondo-imagen-message">
            <span className="fondo-imagen-message">
              <img src={message} alt="foto-Open" />
            </span>
          </button>
          <VStack spacing={"10px"}>
            <p className="info-contacto-mensaje">Primer mensaje</p>
            <p className="info-contacto-fecha">10/11/2023</p>
          </VStack>
        </HStack>
        <HStack marginTop={"10px"}>
          <button className="fondo-imagen-message">
            <span className="fondo-imagen-message">
              <img src={message} alt="foto-Open" />
            </span>
          </button>
          <VStack spacing={"10px"} marginTop={"0px"}>
            <p className="info-contacto-mensaje">Último mensaje</p>
            <p className="info-contacto-fecha">10/11/2023</p>
          </VStack>
        </HStack>
      </VStack>
    </div>
  );
}

export default InfoContacto;
