import '../App.css';
import { HStack,VStack,Box } from '@chakra-ui/react';
import avatar from "../images/avatar.png";
function PersonaVentaOverview({ nombre, numero, ganancia }) {

  return (
        <HStack className='div-persona-venta' >
            <Box marginRight="10px">
            <img className="foto-usuario-overview" src={avatar} alt="foto-usuario"></img>
            </Box>
        <VStack spacing={"-1px"}>
            <p className='persona-overview-nombre'>{nombre}</p>
            <p className='persona-overview-telefono'>{numero}</p>
        </VStack>
        <Box ml="auto">
            <p>+${ganancia}</p>
        </Box>
        </HStack>

  );
}

export default PersonaVentaOverview;
