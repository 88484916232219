import '../App.css';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,VStack, HStack } from "@chakra-ui/react";
import React, { useState,useEffect } from "react";
import Calendario from './Calendario';

function PopupCalendario() {
  const [endDate, setEndDate] = useState(new Date("2024-01-23T00:00:00"));
  const [startDate, setStartDate] = useState(new Date("2024-01-23T00:00:00"));
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [startDateInput, setStartDateInput] = useState("");
  const [endDateInput, setEndDateInput] = useState("");

  const [eleccion,setEleccion] = useState(1);

  const handleDateSelect = (date) => {
    console.log(eleccion)
    if (eleccion === 1) {
      setStartDate(date);
      setEleccion(2)
    } 
    else {
      setEndDate(date);
      setEleccion(1)
    }
  };
  const handleCancel = () => {
    setStartDate(new Date("0-00-00000:00:00")); 
    setEndDate(new Date("0-00-00000:00:00"));
    setStartDateInput("");
    setEndDateInput("");
    setEleccion(1);
  };
  const handleStartDateChange = (e) => {
    setStartDateInput(e.target.value);
    console.log(e.target.value);
    setStartDate(new Date(e.target.value));
  };

  const handleEndDateChange = (e) => {
    setEndDateInput(e.target.value);
    console.log(e.target.value);
    setEndDate(new Date(e.target.value));
  };

  const handleSubmit = () =>{
    console.log(startDate);
    console.log(endDate);
  }
  useEffect(() => {
    console.log("Eleccion actualizada:", eleccion);
  }, [eleccion]);
  return (
    <>
      <button onClick={onOpen} className="boton-calendario">
      {startDate.toLocaleDateString()},{endDate.toLocaleDateString()}
      </button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="popup-calendario">
          <ModalHeader>Calendario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack>
              <Calendario onDateSelect={handleDateSelect} />
            </HStack>
          </ModalBody>

          <ModalFooter >
          <VStack className='div-fechas-calendario'>
              <HStack spacing={"2px"}>
                <div className="div-fechas">
                  <HStack>
                    <Text>Fecha Inicio</Text>
                  </HStack>
                </div>
                <input
                  className="input-fecha"
                  placeholder={startDate ? startDate.toLocaleDateString() : ""}
                  value={startDateInput}
                  onChange={handleStartDateChange}
                />
                <div className="div-fechas">
                  <HStack>
                    <Text>Fecha Fin</Text>
                  </HStack>
                </div>
                <input
                  className="input-fecha"
                  placeholder={endDate ? endDate.toLocaleDateString() : ""}
                  value={endDateInput}
                  onChange={handleEndDateChange}
                />
              </HStack>
              <HStack>
                <button className="archivo" onClick={handleSubmit}>Guardar</button>
                <button className='archivo' onClick={handleCancel}>Cancelar</button>
              </HStack>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}


export default PopupCalendario;