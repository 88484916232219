
import TendenciasTemporales from "../Pages/TendenciasTemporales";
import TemperaturaConversaciones from "../Pages/TemperaturaConversaciones";
import '../App.css';

import BotonesAnalitica from "../Components/BotonesAnalitica.js"
import { useState } from "react";
function MenuOverview() {
    const [opcion, setOpcion] = useState(0);
    return (
        <>
        <BotonesAnalitica opcion={opcion} setOpcion={setOpcion}/>
        {opcion === 0 && <TendenciasTemporales/>}
        {opcion === 1 && <TemperaturaConversaciones/>}
        </>
    );
}
  
  export default MenuOverview;