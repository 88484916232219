import { VStack, HStack } from "@chakra-ui/react";
import "../App.css";
import Avatar from "../images/avatarchatbot.png";
import { useParams } from "react-router-dom";
import { useRef, useState } from "react";
function General() {
  const nombre = useParams();

  const [files, setFiles] = useState(null);
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const inputRef = useRef();
  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };
  //send files to the server
  const handleUpload = () => {};
  if (files)
    return (
      <>
        <div>
          <p className="info-chatbot">Información del chatbot</p>
        </div>
        <HStack spacing="120px">
          <VStack className="div-usuario">
            <img
              src={Avatar}
              alt="foto-usuario"
              className="imagen-usuario"
            ></img>
            <p className="usuario-nombre">{nombre.id}</p>
            <p className="usuario-ia">AI Chatbot</p>
          </VStack>
          <VStack
            className="div-segundo"
            spacing="30px"
            alignItems="flex-start"
          >
            <p className="segundo-nombre">Nombre</p>
            <input
              className="input-nombre-chatbot"
              placeholder={nombre.id}
            ></input>
            <p className="segundo-nombre">Número de contactaciones</p>
            <p className="segundo-id">x</p>
          </VStack>
          <VStack
            className="div-tercero"
            spacing="30px"
            alignItems="flex-start"
          >
            <p className="segundo-nombre">Foto de perfil</p>
            <div>
              <ul className="mapfiles">
                {Array.from(files).map((file, idx) => (
                  <li key={idx}>{file.name}</li>
                ))}
              </ul>
            </div>
            <p className="tercero-id">Campaña</p>
            <p className="tercero-id-texto">Do it right</p>
            <HStack>
              <button onClick={handleUpload} className="archivo">
                Guardar
              </button>
              <button onClick={() => setFiles(null)} className="cancelar">
                Cancelar
              </button>
            </HStack>
          </VStack>
        </HStack>
      </>
    );
  return (
    <>
      <div>
        <p className="info-chatbot">Información del chatbot</p>
      </div>
      <HStack spacing="120px">
        <VStack className="div-usuario">
          <img src={Avatar} alt="foto-usuario" className="imagen-usuario"></img>
          <p className="usuario-nombre">{nombre.id}</p>
          <p className="usuario-ia">AI Chatbot</p>
        </VStack>
        <VStack className="div-segundo" spacing="30px" alignItems="flex-start">
          <p className="segundo-nombre">Nombre</p>
          <input className="input-nombre-chatbot" value={nombre.id}></input>
          <p className="segundo-nombre">ID Público</p>
          <p className="segundo-id">c8b8f315-5602</p>
        </VStack>
        <VStack className="div-tercero" spacing="30px" alignItems="flex-start">
          <p className="segundo-nombre">Foto de perfil</p>
          <div
            className="archivo"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              type="file"
              accept=".jpg"
              multiple
              onChange={(event) => setFiles(event.target.files)}
              hidden
              ref={inputRef}
            />
            <button
              className="archivo"
              onClick={() => inputRef.current.click()}
            >
              Elije archivo
            </button>
          </div>
          <HStack>
            <button onClick={handleUpload} className="archivo">
              Guardar
            </button>
            <button onClick={() => setFiles(null)} className="cancelar">
              Cancelar
            </button>
          </HStack>
        </VStack>
      </HStack>
    </>
  );
}

export default General;

/*
    <HStack spacing='162px'>
        <VStack className='div-usuario' justifyContent='flex-start' alignItems={"center"}>
          <img className="foto-usuario-chatbot" src={Avatar} alt='foto-usuario'></img>
          <p className='nombre-usuario'>{nombre.id}</p>
          <p className='ai-chatbot'>AI Chatbot</p>
        </VStack>
        <VStack User
justifyContent='flex-start' alignItems={"left"} spacing='40px' marginLeft="-50px">
          <p className='nombre-id-publico'>Nombre</p>
          <input type="text" className='input-nombre-general'></input>
          <p className='nombre-publico'>ID Público</p>
          <p className='texto-ID-General'>c8b8f315-5602</p>
        </VStack>
        <VStack justifyContent='flex-start' alignItems={"left"} spacing='40px'  marginLeft="-100px">
          <p className='titulo'>Foto de perfil</p>
          <button className='archivo'>Elije archivo</button>
          <p className='titulo-id'>ID Público</p>
          <p className='texto-general'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi iaculis egestas arcuntum. Praesent et venenatis eros.</p>
          <HStack spacing="40px">
            <button className='archivo'>Guardar</button>
            <button className='archivo'>Cancelar</button>
          </HStack>
        </VStack>
    </HStack>
    */
