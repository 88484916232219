import "../App.css";
import Overview from "../images/overview.png";
import Inbox from "../images/inbox.png";
import Chatbot from "../images/chabot.png";
import Campaigns from "../images/campaigns.png";
// import Usage from "../images/usage.png";
// import Setting from "../images/settings.png";
import Logout from "../images/logout.png";
import Logo from "../images/image5.png";

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import React from "react";
import { VStack } from "@chakra-ui/react";
function Navbar({ opcion, setOpcion, signOut }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedButtons, setSelectedButtons] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    // Actualizar el estado de los botones según la ubicación actual
    const currentPath = location.pathname;
    const newSelectedButtons = [false, false, false, false, false];

    if (currentPath === "/Overview") {
      newSelectedButtons[0] = true;
    } else if (currentPath === "/Inbox") {
      newSelectedButtons[1] = true;
    } else if (currentPath === "/Chatbot") {
      newSelectedButtons[2] = true;
    } else if (currentPath === "/Campaigns") {
      newSelectedButtons[3] = true;
    } else if (currentPath === "/Usage") {
      newSelectedButtons[4] = true;
    } else if (currentPath === "/Settings") {
      newSelectedButtons[5] = true;
    } else if (currentPath === "/") {
      newSelectedButtons[6] = true;
    }

    setSelectedButtons(newSelectedButtons);
  }, [location.pathname]);

  const handleClick = (index, path) => {
    setSelectedButtons(new Array(6).fill(false)); // Reiniciar todos los estados a false
    setSelectedButtons((prev) => ({ ...prev, [index]: true }));
    navigate(path);
  };
  return (
    <VStack className="dashboard-div-pernexium">
      <img
        src={Logo}
        alt="foto-Logo"
        className="dashboard-imagen-pernexium"
      ></img>
      <button
        className={
          selectedButtons[0]
            ? "dashboard-boton-seleccionado"
            : "dashboard-boton"
        }
        onClick={() => handleClick(0, "/Overview")}
      >
        <img src={Overview} alt="foto-overview" className="imagen-navbar"></img>
        Statistics
      </button>

      <button
        className={
          selectedButtons[1]
            ? "dashboard-boton-seleccionado"
            : "dashboard-boton"
        }
        onClick={() => handleClick(1, "/Inbox")}
      >
        <img src={Inbox} alt="foto-Inbox" className="imagen-navbar"></img>
        Inbox
      </button>

      <button
        className={
          selectedButtons[2]
            ? "dashboard-boton-seleccionado"
            : "dashboard-boton"
        }
        onClick={() => handleClick(2, "/Chatbot")}
      >
        <img src={Chatbot} alt="foto-Chatbot" className="imagen-navbar"></img>
        Chatbot
      </button>

      <button
        className={
          selectedButtons[6]
            ? "dashboard-boton-seleccionado-logout"
            : "dashboard-boton-logout"
        }
        onClick={signOut}
      >
        <img src={Logout} alt="foto-Setting" className="imagen-navbar"></img>
        Log out
      </button>
    </VStack>
  );
}

export default Navbar;
